@import (reference) "vars.less";

.warning-text {
    color: @alert-warning-text;
    display: none;

    &.active {
        display: block;
    }
}

.error-text {
    color: @alert-danger-text;
    display: none;

    &.active {
        display: block;
    }
}


.ergoError {
    text-align: left;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
