@import (reference) "vars.less";

td.shrink, th.shrink {
    white-space: nowrap;
    width: 1px;
}

.slimButton {
    background-color: transparent;
    border: 0;
    height: 20px;
    padding: 0 2px;

    a& {
        color: black;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }

    &:disabled {
        color: #777;
    }

    &:focus {
        outline: none;
    }
}

.slimDivider {
    vertical-align: text-bottom;
    display: inline-block;
    border-left: 1px solid #ddd;
    margin: 0px 5px;
    height: 16px;
}

table {

    &.mainTable {
        background-color: #F7F7F7;

        > thead > tr > th {
            color: @progress-bar-info-bg;
            font-size: 15px;
            letter-spacing: 1px;
        }

        > tbody {
            padding: 10px;
        }
    }

    &.separate-top {
        margin-top: 10px;
        > thead:first-child > tr:first-child > th {
            border-top: 1px solid #ccc;
            padding-top: (@table-condensed-cell-padding + 5px);
        }
    }

    &.table-adjusted {
        > tbody > tr, > thead > tr {
            > th {
                width: 1px;
                white-space: nowrap;
            }
            
            > td, > th {
                &:not(:first-child) {
                    padding-left: 10px;
                }

                &.dynamic-cell {
                    max-width: 200px;
                    white-space: nowrap;
                    overflow: hidden;
                    -ms-text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;
                }

                &.static-cell {
                    width: 1px;
                    white-space: nowrap;
                }
            }
        }
    }

    &.table-sortable {
        > thead > tr > th {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }

    &.table-framed {
        border: 1px solid lightgray;

        > thead > tr, > tbody > tr {
            > td:first-child, > th:first-child {
                padding-left: 10px;
            }

            > td:last-child, > th:last-child {
                padding-right: 10px;
            }
        }
    }

    &.table {
        > thead > tr > th {
            vertical-align: top;
        }

        > tbody {
            > tr.st-selected {
                background-color: #E8D3B9;
            }

            > tr.isarchived {
                > td.content-cell {
                    font-weight: bold;
                    font-style: italic;
                }
            }

            > tr > td {
                vertical-align: middle;
            }
        }
    }
}

.tableLink {
    > .glyphicon {
        color: black;
    }
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.tucktop {
        align-items: flex-start;
    }

    &.tuckbottom {
        align-items: flex-end;
    }

    &.tuckleft {
        justify-content: flex-start;
    }

    &.tuckright {
        justify-content: flex-end;
    }

    &.stretch {
        align-items: stretch;
    }

    &.spaced {
        > button, > div {
            & + button, & + div {
                margin-left: 10px;
            }
        }
    }

    > .horizontal-fill {
        flex: 1 1 auto;
    }
}

.vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.tucktop {
        justify-content: flex-start;
    }

    &.tuckbottom {
        justify-content: flex-end;
    }

    &.tuckleft {
        align-items: flex-start;
    }

    &.tuckright {
        align-items: flex-end;
    }

    &.stretch {
        align-items: stretch;
    }

    &.spaced {
        > button, > div {
            & + button, & + div {
                margin-top: 5px;
            }
        }
    }
}

.informationList {
    display: table;

    > div {
        display: table-row;

        > label {
            display: table-cell;
        }

        > div {
            display: table-cell;
            padding-left: 10px;
        }
    }
}


.st-sort-ascent:before {
    content: '\25B2';
}

.st-sort-descent:before {
    content: '\25BC';
}

[st-sort], [data-st-sort] {
    cursor: pointer;
}