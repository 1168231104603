@import (reference) "vars.less";

body {
    background-color: whitesmoke;
    margin: 0;
    padding: 0;
    position: relative;

    > .container-background {
        z-index: -1000;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        // bootstrap sets body padding in order to account for scrollbar width.
        // By explicitly inheriting, the same adjustment is applied to the
        // fixed underlay.
        padding: inherit;
        margin: 0;

        > .container {
            height: 100%;
            background-color: white;
            border-right: 1px solid #dddddd;
            border-left: 1px solid #dddddd;
        }
    }

    > .container {
        // Subtract the size of the bottom border.
        margin-bottom: (@footer-height - 1px);
        padding-bottom: (@grid-gutter-width / 2);
    }
}

.navbar {
    margin-bottom: 0;
    border-radius: 0;

    .brand {
        padding-top: 6px;
        padding-bottom: 0;

        img {
            height: 40px;
        }
    }
}

.pageRoot {
    padding: 20px;

    > header {
        margin: 0 0 40px 0;
    }
}

.tabRoot {
    padding: 20px;

    > header {
        margin: 0 0 40px 0;
    }
}

.tab-section + .tab-section {
    margin: 20px -20px 0 -20px;
    padding: 20px 20px 0 20px;
    border-top: 1px solid #ddd;
}

section {
    &.topSection {
        display: flex;
        flex-direction: row;

        > header {
            flex: 1 1 auto;
        }

        > aside {
            align-self: flex-end;
            flex: 0 0 auto;
            margin: 10px 0;

            &.topAligned {
                align-self: flex-start;
                margin-top: 23px;
            }
        }
    }

    &.mainSection {
        background-color: #F7F7F7;
        border: 1px solid lightgray;
        padding: 20px;
    }

    &.compactMainSection {
        background-color: #F7F7F7;
        border: 1px solid lightgray;
        padding: 20px 20px 10px 20px;
    }

    &.tabControl {
        border: 1px solid @navbar-default-border;
        border-radius: 0;

        > .navbar {
            border: none;
            border-bottom: 1px solid @navbar-default-border;
            border-radius: 0;

            .navbar-nav {
                margin: 0;
            }
        }

        > .tabBody {
            background-color: #FBFBFB;
        }
    }

    & + &, & + form {
        margin-top: 20px;
    }
}

.tm-footer {
    position: fixed;
    z-index: @zindex-navbar-fixed;
    bottom: 0;
    left: 0;
    
    width: 100%;
    height: @footer-height;

    background-color: @navbar-default-bg;
    border: 1px solid @navbar-default-border;
    border-radius: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;

    > .footer-content {
        @media (min-width: @screen-sm-min) {
            width: @container-sm;
        }

        @media (min-width: @screen-md-min) {
            width: @container-md;
        }

        @media (min-width: @screen-lg-min) {
            width: @container-lg;
        }
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.tw-alert-dialog {
    &__error {
        color: red;
        font-weight: bold;
    }
    > p > span {
        white-space: pre-line;
    }
}