[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
textarea {
  resize: vertical;
}
.btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.has-error .input-group .input-group-btn .btn {
  border-color: #a94442;
}
.modal .modal-dialog .modal-content {
  background-color: #EFEFEF;
}
.modal .modal-dialog .modal-content .modal-body {
  background-color: white;
  padding: 20px 20px;
}
.modal .modal-dialog .modal-content .modal-footer .buttons button {
  min-width: 100px;
}
h1,
.h1 {
  color: black;
  letter-spacing: 1px;
  font-size: 30px;
}
h2,
.h2 {
  color: black;
  letter-spacing: 1px;
  font-size: 21px;
}
h4 {
  color: #5bc0de;
  letter-spacing: 1px;
  margin: 0 0 20px 0;
}
h5 {
  color: #5bc0de;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0 0 10px 0;
}
label {
  color: dimgray;
  font-weight: bold;
}
label.plain-checkbox {
  color: #333;
}
.has-error .checkbox > .checkbox-label {
  color: #a94442;
}
.input-sm.select2-container-multi > .select2-choices > .select2-search-choice {
  float: none;
  border: none;
  margin: 0;
}
.input-sm.select2-container-multi > .select2-choices > .select2-search-choice:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.input-sm.select2-container-multi > .select2-choices > .select2-search-choice:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.input-sm.select2-container-multi > .select2-choices > .select2-search-choice:nth-last-child(2) {
  border-bottom: 1px solid #CCC;
}
.table > thead > tr > th {
  border-bottom: none;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: white;
}
body {
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  position: relative;
}
body > .container-background {
  z-index: -1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: inherit;
  margin: 0;
}
body > .container-background > .container {
  height: 100%;
  background-color: white;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
body > .container {
  margin-bottom: 49px;
  padding-bottom: 15px;
}
.navbar {
  margin-bottom: 0;
  border-radius: 0;
}
.navbar .brand {
  padding-top: 6px;
  padding-bottom: 0;
}
.navbar .brand img {
  height: 40px;
}
.pageRoot {
  padding: 20px;
}
.pageRoot > header {
  margin: 0 0 40px 0;
}
.tabRoot {
  padding: 20px;
}
.tabRoot > header {
  margin: 0 0 40px 0;
}
.tab-section + .tab-section {
  margin: 20px -20px 0 -20px;
  padding: 20px 20px 0 20px;
  border-top: 1px solid #ddd;
}
section.topSection {
  display: flex;
  flex-direction: row;
}
section.topSection > header {
  flex: 1 1 auto;
}
section.topSection > aside {
  align-self: flex-end;
  flex: 0 0 auto;
  margin: 10px 0;
}
section.topSection > aside.topAligned {
  align-self: flex-start;
  margin-top: 23px;
}
section.mainSection {
  background-color: #F7F7F7;
  border: 1px solid lightgray;
  padding: 20px;
}
section.compactMainSection {
  background-color: #F7F7F7;
  border: 1px solid lightgray;
  padding: 20px 20px 10px 20px;
}
section.tabControl {
  border: 1px solid #e7e7e7;
  border-radius: 0;
}
section.tabControl > .navbar {
  border: none;
  border-bottom: 1px solid #e7e7e7;
  border-radius: 0;
}
section.tabControl > .navbar .navbar-nav {
  margin: 0;
}
section.tabControl > .tabBody {
  background-color: #FBFBFB;
}
section + section,
section + form {
  margin-top: 20px;
}
.tm-footer {
  position: fixed;
  z-index: 1030;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #f8f8f8;
  border: 1px solid #e7e7e7;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tm-footer > .footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .tm-footer > .footer-content {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .tm-footer > .footer-content {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .tm-footer > .footer-content {
    width: 1170px;
  }
}
.tw-alert-dialog__error {
  color: red;
  font-weight: bold;
}
.tw-alert-dialog > p > span {
  white-space: pre-line;
}
td.shrink,
th.shrink {
  white-space: nowrap;
  width: 1px;
}
.slimButton {
  background-color: transparent;
  border: 0;
  height: 20px;
  padding: 0 2px;
}
a.slimButton {
  color: black;
  text-decoration: none;
}
a.slimButton:hover {
  text-decoration: none;
}
.slimButton:disabled {
  color: #777;
}
.slimButton:focus {
  outline: none;
}
.slimDivider {
  vertical-align: text-bottom;
  display: inline-block;
  border-left: 1px solid #ddd;
  margin: 0px 5px;
  height: 16px;
}
table.mainTable {
  background-color: #F7F7F7;
}
table.mainTable > thead > tr > th {
  color: #5bc0de;
  font-size: 15px;
  letter-spacing: 1px;
}
table.mainTable > tbody {
  padding: 10px;
}
table.separate-top {
  margin-top: 10px;
}
table.separate-top > thead:first-child > tr:first-child > th {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
table.table-adjusted > tbody > tr > th,
table.table-adjusted > thead > tr > th {
  width: 1px;
  white-space: nowrap;
}
table.table-adjusted > tbody > tr > td:not(:first-child),
table.table-adjusted > thead > tr > td:not(:first-child),
table.table-adjusted > tbody > tr > th:not(:first-child),
table.table-adjusted > thead > tr > th:not(:first-child) {
  padding-left: 10px;
}
table.table-adjusted > tbody > tr > td.dynamic-cell,
table.table-adjusted > thead > tr > td.dynamic-cell,
table.table-adjusted > tbody > tr > th.dynamic-cell,
table.table-adjusted > thead > tr > th.dynamic-cell {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
table.table-adjusted > tbody > tr > td.static-cell,
table.table-adjusted > thead > tr > td.static-cell,
table.table-adjusted > tbody > tr > th.static-cell,
table.table-adjusted > thead > tr > th.static-cell {
  width: 1px;
  white-space: nowrap;
}
table.table-sortable > thead > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table.table-framed {
  border: 1px solid lightgray;
}
table.table-framed > thead > tr > td:first-child,
table.table-framed > tbody > tr > td:first-child,
table.table-framed > thead > tr > th:first-child,
table.table-framed > tbody > tr > th:first-child {
  padding-left: 10px;
}
table.table-framed > thead > tr > td:last-child,
table.table-framed > tbody > tr > td:last-child,
table.table-framed > thead > tr > th:last-child,
table.table-framed > tbody > tr > th:last-child {
  padding-right: 10px;
}
table.table > thead > tr > th {
  vertical-align: top;
}
table.table > tbody > tr.st-selected {
  background-color: #E8D3B9;
}
table.table > tbody > tr.isarchived > td.content-cell {
  font-weight: bold;
  font-style: italic;
}
table.table > tbody > tr > td {
  vertical-align: middle;
}
.tableLink > .glyphicon {
  color: black;
}
.horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.horizontal.tucktop {
  align-items: flex-start;
}
.horizontal.tuckbottom {
  align-items: flex-end;
}
.horizontal.tuckleft {
  justify-content: flex-start;
}
.horizontal.tuckright {
  justify-content: flex-end;
}
.horizontal.stretch {
  align-items: stretch;
}
.horizontal.spaced > button + button,
.horizontal.spaced > div + button,
.horizontal.spaced > button + div,
.horizontal.spaced > div + div {
  margin-left: 10px;
}
.horizontal > .horizontal-fill {
  flex: 1 1 auto;
}
.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.vertical.tucktop {
  justify-content: flex-start;
}
.vertical.tuckbottom {
  justify-content: flex-end;
}
.vertical.tuckleft {
  align-items: flex-start;
}
.vertical.tuckright {
  align-items: flex-end;
}
.vertical.stretch {
  align-items: stretch;
}
.vertical.spaced > button + button,
.vertical.spaced > div + button,
.vertical.spaced > button + div,
.vertical.spaced > div + div {
  margin-top: 5px;
}
.informationList {
  display: table;
}
.informationList > div {
  display: table-row;
}
.informationList > div > label {
  display: table-cell;
}
.informationList > div > div {
  display: table-cell;
  padding-left: 10px;
}
.st-sort-ascent:before {
  content: '\25B2';
}
.st-sort-descent:before {
  content: '\25BC';
}
[st-sort],
[data-st-sort] {
  cursor: pointer;
}
.warning-text {
  color: #8a6d3b;
  display: none;
}
.warning-text.active {
  display: block;
}
.error-text {
  color: #a94442;
  display: none;
}
.error-text.active {
  display: block;
}
.ergoError {
  text-align: left;
}
.ergoError ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
