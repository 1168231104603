@import (reference) "vars.less";

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

textarea {
    resize: vertical;
}

.btn {
    padding: @padding-small-vertical @padding-small-horizontal;
    font-size: @font-size-small;
    line-height: @line-height-small;
    border-radius: @border-radius;
}

.has-error {
  .input-group {
      .input-group-btn {
          .btn {
            border-color: @state-danger-text;
          }
      }
  }
}

.modal {
    .modal-dialog {
        .modal-content {
            background-color: #EFEFEF;

            .modal-header {

            }

            .modal-body {
                background-color: white;
                padding: 20px 20px;
            }

            .modal-footer {
                .buttons {
                    button {
                        min-width: 100px;
                    }
                }
            }
        }
    }
}

h1, .h1 {
    color: black;
    letter-spacing: 1px;
    font-size: floor((@font-size-base * 2.2));
}

h2, .h2 {
    color: black;
    letter-spacing: 1px;    
    font-size: floor((@font-size-base * 1.5));
}


h4 {
    color: @brand-info;
    letter-spacing: 1px;
    margin: 0 0 20px 0;
}

h5 {
    color: @brand-info;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0 0 10px 0;
}

label {
    color: dimgray;
    font-weight: bold;
}

label.plain-checkbox {
    color: #333;
}

.has-error {
    .checkbox > .checkbox-label {
        color: @state-danger-text;
    }
}

.input-sm.select2-container-multi {
    > .select2-choices {
        > .select2-search-choice {
            float: none;
            border: none;
            margin: 0;
            &:not(:first-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            &:not(:last-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:nth-last-child(2) {
                border-bottom: 1px solid #CCC;
            }
        }
    }
}

.table {
    > thead > tr > th {
        border-bottom: none;
    }
}

.table-striped {
    > tbody > tr:nth-of-type(odd) {
        background-color: white;
    }
}